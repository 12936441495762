<template>
  <div class="middel flex-container">
    <img
      :src="
        product_list.goods_img_480 ||
          product_list.goods_image_url ||
          product_list.image_url
      "
      class="pro_img"
    />
    <div class="info">
      <h2>{{ product_list.goods_name }}</h2>
      <span class="sku" v-if="product_list.goods_num"
        >数量:{{ product_list.goods_num }}个</span
      >
      <span class="sku" v-else>数量:1个</span>
      <!-- <b class='active' v-if='showDom == true'>新人首单特价</b> -->
    </div>
    <div class="price" v-if="showDom == true">
      <p>￥{{ product_list.goods_pay_price || product_list.goods_price }}</p>
      <b>x{{ product_list.goods_num }}</b>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: ["product_list", "showDom"],
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

.middel {
  // margin-top: rem(32);
  padding: rem(32) rem(21);

  .pro_img {
    width: rem(140);
    height: rem(140);
    border-radius: rem(8);
    display: block;
    margin-right: rem(24);
    // margin-right: rem(24);
  }
  .info {
    width: rem(380);
    margin-right: rem(48);
    h2 {
      // font-size: rem(24);
      // font-family: 'pfb';
      font-size: rem(24);
      font-weight: bold;
      color: #808080;
    }
    .sku {
      // font-size: rem(22);
      // font-family: 'pf';
      font-size: rem(22);
      color: #b3b3b3;
      display: block;
      margin-top: rem(2);
    }
    .active {
      display: inline-block;
      margin-top: rem(6);
      padding: rem(6);

      height: rem(18);
      line-height: rem(18);
      text-align: center;
      // font-size: rem(18);
      font-size: rem(18);
      font-weight: bold;
      // font-family: 'pfb';
      color: #ff4848;
      background: #ffebeb;
      border-radius: rem(4);
    }
  }
  .price {
    // font-family: 'pf';

    p {
      font-size: rem(30);
      font-weight: bold;
      color: #343434;
      display: block;
      text-align: right;
    }
    b {
      text-align: right;
      display: block;
      font-size: rem(22);

      color: #b3b3b3;
    }
  }
}
</style>
