<template>
  <div id="service">
    <div class="xiaoet_order_bar">
      <svg
        @click="$router.go(-1)"
        class="xet_returnPage"
        width="18"
        height="32"
        viewBox="0 0 18 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 2L2 16L16 30"
          stroke="#222222"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div class="flex-container">
        <div
          @click="getTab(item.key)"
          :class="item.key == tabActive ? 'act' : ''"
          v-for="item in tab"
          :key="item.key"
        >
          {{ item.name }}
          <span v-if="item.key == tabActive"></span>
        </div>
      </div>
    </div>

    <van-list
      error-text="请求失败，点击重新加载"
      v-model="loading"
      :finished="finished"
      finished-text="已无更多明细"
      @load="onLoad"
    >
      <div class="service_ul">
        <div
          class="service_li"
          v-for="items in service_list"
          @click="goDetail(items.refund_id, items.refund_type)"
        >
          <div class="order_id flex-container">
            <p class="flex1">服务单号：{{ items.refund_sn }}</p>
            <img
              src="@image/order/service_money.png"
              v-if="items.refund_type == 1"
            />
            <img
              src="@image/order/service_product.png"
              v-if="items.refund_type == 2"
            />
            <b v-if="items.refund_type == 1">退款</b>
            <b v-if="items.refund_type == 2">退货</b>
          </div>

          <template v-for="item in items.goods_info">
            <productList
              class="p36"
              :product_list="item"
              showDom="true"
            ></productList>
          </template>

          <!-- 退款 -->
          <!-- <第一步> -->
          <div
            class="static flex-container"
            v-if="items.refund_state == 1 && items.seller_state != 2"
          >
            <h3>已提交申请</h3>
            <p>请您耐心等候</p>
          </div>

          <!-- 第二步   退款流程  -->
          <div
            class="static flex-container"
            v-if="
              items.seller_state == 2 &&
                items.return_type == 1 &&
                items.refund_type == 1 &&
                items.refund_state != 3
            "
          >
            <h3>商家处理中</h3>
            <p>请您耐心等候</p>
          </div>

          <!-- 第二步   退货退款流程  -->
          <!-- <div class="static flex-container" v-if='items.refund_state == 1 && items.seller_state != 2 && items.reason_id > 0'>
                <h3>财务处理中</h3>
                <p>正在进行最后的处理</p>
              </div> -->

          <!-- <第二步  退货流程 > -->
          <div
            class="static flex-container"
            v-if="
              items.seller_state == 2 &&
                items.return_type == 1 &&
                items.refund_type == 2 &&
                items.refund_state != 3
            "
          >
            <h3>商家处理中</h3>
            <p>请您耐心等候</p>
          </div>

          <div
            class="static flex-container"
            v-if="
              items.seller_state == 2 &&
                items.goods_state == 1 &&
                items.refund_type == 2 &&
                items.return_type == 2
            "
          >
            <h3>用户处理中</h3>
            <p>需要您进行发货处理</p>
          </div>

          <div
            class="static flex-container"
            v-if="
              items.seller_state == 2 &&
                items.goods_state == 2 &&
                items.refund_type == 2
            "
          >
            <h3>商家处理中</h3>
            <p>快递正在马不停蹄送往商家手中</p>
          </div>

          <div
            class="static flex-container"
            v-if="
              items.seller_state == 2 &&
                items.goods_state == 3 &&
                items.refund_type == 2
            "
          >
            <h3>商家处理中</h3>
            <p>快递没收到，请联系客服</p>
          </div>

          <div
            class="static flex-container"
            v-if="
              items.seller_state == 2 &&
                items.goods_state == 4 &&
                items.refund_state != 3 &&
                items.refund_type == 2
            "
          >
            <h3>财务处理中</h3>
            <p>正在进行最后的处理</p>
          </div>

          <div
            class="static flex-container"
            v-if="items.refund_state == 3 && items.seller_state != 3"
          >
            <h3>售后完成</h3>
            <p>退款成功￥{{ items.refund_amount }}</p>
          </div>

          <div
            class="static flex-container"
            v-if="
              items.refund_state == 3 &&
                items.seller_state == 3 &&
                items.refund_type == 2
            "
          >
            <h3>商家通知</h3>
            <p>驳回申请，有疑问请联系客服</p>
          </div>

          <div
            class="static flex-container"
            v-if="items.seller_state == 3 && items.refund_type == 1"
          >
            <h3>商家通知</h3>
            <p>驳回申请，有疑问请联系客服</p>
          </div>

          <!-- <div class="static flex-container" v-if='items.refund_state == 3 && items.refund_type == 1'>
                <h3 >完成</h3>
                <p>退款成功￥{{items.refund_amount}}</p>
              </div> -->
        </div>

        <!-- <div class="service_li">
              <div class="order_id flex-container">
                <p class='flex1'>服务单号：65813721</p>
                <img src="@image/order/service_product.png" a>
                <b>退货</b>
              </div>
              <productList class='p36'></productList>
              <div class="static flex-container">
                  <h3 >已提交申请</h3>
                  <p>请您耐心等候</p>

              </div>
          </div> -->
      </div>
    </van-list>
  </div>
</template>
<script>
import productList from "../list/productList";

import { List } from "vant";
import { post } from "@get/http";
export default {
  name: "",
  components: {
    productList,
    vanList: List,
  },
  data: () => ({
    tabActive: 0,
    tab: [
      {
        name: "十九匠商城",
        key: 0,
      },
      {
        name: "课程学习",
        key: 1,
      },
      {
        name: "直播购物",
        key: 2,
      },
    ],
    loading: false,
    finished: false,
    page: 1,

    time_type: 1,
    per_page: 10,
    service_list: [],
  }),
  methods: {
    getTab(index) {
      if (index == 1 || index == 2) {
        this.goServiceList();
      }
      this.tabActive = index;
    },
    goServiceList() {
      window.location.href =
        "https://appg5q9tdm86610.h5.xiaoeknow.com/v1/usercenter/serviceApply/serviceList";
    },
    goDetail(refund_id, refund_type) {
      this.$router.push({
        name: "OrderServiceDetail",
        query: { refund_id, refund_type },
      });
    },
    async onLoad() {
      this.loading = true;
      // /Memberreturn/get_return_list
      // /v2.MemberAfterSale/index
      let result = await post("/v2.MemberAfterSale/index", {
        data: {
          page: this.page,
          per_page: this.per_page,
          time_type: this.time_type,
        },
      });
      let res = result.data;
      // console.log(res);
      this.service_list.push(...res);
      this.page++;
      this.loading = false;

      if (res.length < this.per_page) {
        this.loading = true;
        this.finished = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

#service {
  height: 100%;
  background: #f8f8f8;
  padding-top: rem(24);
}
.p36 {
  padding: rem(36) 0 rem(32);
}
.service_ul {
  margin-top: rem(20);
}
.service_li {
  border-radius: rem(10);
  background: #fff;
  width: rem(710);
  margin: 0 auto rem(24);
  padding: rem(30) rem(24);
  box-sizing: border-box;

  .order_id {
    align-items: center;

    p {
      font-size: rem(22);
      color: #808080;
    }
    img {
      width: rem(26);
      height: rem(26);
      display: block;
    }
    b {
      font-size: rem(26);
      font-weight: bold;
      color: #343434;
      margin-left: rem(12);
    }
  }
  .static {
    background: url("~@image/me/icon_more.png") no-repeat center right;
    background-size: rem(12) rem(22);
    height: rem(92);
    line-height: rem(92);
    margin-right: rem(56);
    h3 {
      font-size: rem(26);
      font-weight: bold;
      color: #343434;
      margin: 0 rem(36) 0 rem(32);
    }
    p {
      font-size: rem(26);
      color: #808080;
    }
  }
}

.xiaoet_order_bar {
  display: flex;
  width: rem(750);
  margin: 0 auto;
  align-items: center;
  .xet_returnPage {
    width: rem(14);
    height: rem(28);
    margin: 0 rem(23) 0 rem(30);
  }
  .flex-container {
    height: rem(72);
    align-items: center;
    flex: 1;
    // justify-content: space-between;
    div {
      font-size: rem(30);
      color: #666666;
      position: relative;
      margin: 0 rem(23);
      span {
        position: absolute;
        width: rem(36);
        height: rem(6);
        background: #ff4848;
        border-radius: rem(3);
        bottom: rem(-10);
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    .act {
      color: #ff4848;
    }
  }
  .xet_search {
    width: rem(35.48);
    height: rem(34);
    padding-top: rem(10);
    margin: 0 rem(30) 0 rem(50);
  }
}
</style>
